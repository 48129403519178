import axios, { AxiosResponse } from 'axios'
import gql from '../../../../global/js/utils/gql'
import FreeCMECustomerDetails from '../../types/user/CustomerDetails'
import getStoreName from '../../../../global/js/utils/getStoreName'

interface FreeCmeCreateCustomer {
  data: {
    FreeCMECustomerCreateInput: {
      customer: {
        prefix: string,
        firstname: string,
        middlename: string,
        lastname: string,
        suffix: string,
        email: string,
        password: string,
        freecme_credentials: string,
        freecme_specialty: string,
        freecme_profession: string,
      } | null
    }
  }
  errors?: [{ message: string }]
}

export const createCustomerMutation = gql`
  mutation(
    $prefix: String,
    $firstname: String!,
    $middlename: String,
    $lastname: String!,
    $suffix: String,
    $email: String!,
    $password: String!,
    $state: String!,
    $country: String!,
    $postcode: String!,
    $freecme_credentials: String!,
    $freecme_specialty: String!,
    $freecme_profession: String!,
  ) {
    freeCmeCreateCustomer(
      input: {
        prefix: $prefix,
        firstname: $firstname,
        middlename: $middlename,
        lastname: $lastname,
        suffix: $suffix,
        email: $email,
        password: $password,
        state: $state,
        country: $country,
        postcode: $postcode,
        freecme_credentials: $freecme_credentials,
        freecme_specialty: $freecme_specialty,
        freecme_profession: $freecme_profession,
      }
    ) {
      customer {
        firstname
        lastname
        email
      }
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const createCustomer = async (customerDetails: FreeCMECustomerDetails): Promise<void> => {
  const { data }: AxiosResponse<FreeCmeCreateCustomer> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    },
    data: {
      query: createCustomerMutation,
      variables: {
        prefix: customerDetails.prefix,
        firstname: customerDetails.firstname,
        middlename: customerDetails.middlename,
        lastname: customerDetails.lastname,
        suffix: customerDetails.suffix,
        email: customerDetails.email,
        password: customerDetails.password,
        state: customerDetails.state,
        country: customerDetails.country,
        postcode: customerDetails.postalCode,
        freecme_credentials: customerDetails.freecme_credentials,
        freecme_specialty: customerDetails.freecme_specialty,
        freecme_profession: customerDetails.freecme_profession
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }
}


