
import Wishlist from './Wishlist.vue'

export default {
  components: {
    Wishlist,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      required: true,
    },
    cmeCreditHours: {
      type: Number,
      default: 0,
    },
    cmeContactHours: {
      type: Number,
      default: 0,
    },
    cneCreditHours: {
      type: Number,
      default: 0,
    },
    ceContactHours: {
      type: Number,
      default: 0,
    },
    cpeCeuHours: {
      type: Number,
      default: 0,
    },
    isNew: {
      type: Boolean,
    },
    releaseDate: {
      type: String,
      default: '',
      required: false,
    },
    expiringDate: {
      type: String,
      required: true,
    },
    expiringSoon: {
      type: Boolean,
    },
    progress: {
      type: Number,
      default: null,
      required: false,
    },
    isFeatured: {
      type: Number,
      default: 0,
      required: false,
    },
    sku: {
      type: String,
      required: true,
    },
    showWishlist: {
      type: Boolean,
      default: true,
    },
    showWishlistText: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['productRemovedFromWishlist'],
  computed: {
    productClasses(): Record<string, unknown> {
      return {
        'has-progress': this.progress !== null,
        'has-badge': this.isFeatured === 1 || this.expiringSoon || this.isNew,
      }
    },
  },
  methods: {
    //Updated from product card.  Elasticsearch stores this as a number now and not a string
    checkBlankHours(hours: number): boolean{
      return hours > 0
    },

    formatHours(hours: number): string{
      return (hours !== '') ? `${hours} hr` : hours
    },
    stringWithoutSpace(value: string): string{
      //take any string: remove special characters & spaces, replace with single underscore, make all characters lowercase
      return (value.replace(/[^A-Z0-9]+/ig, '_').toLowerCase())
    },
    /**
     * Emit an event when a product has been removed from wishlist
     */
    productRemovedFromWishlist({ sku }: { sku: string }): void {
      this.$emit('productRemovedFromWishlist', { sku })
    },

    replaceSpecialCharactersAndQuotes(value: string): string {

      // Remove special characters including single and double quotes
      let replacedValue = value.replace(/[^\w\s]/gi, '')

      // Remove single and double quotes
      replacedValue = replacedValue.replace(/['"]/g, '')

      return replacedValue
    },
  },
}
