import { ref } from 'vue'
import { getCustomer } from '@/global/js/magento-api-library/customer/getCustomer'
import { updateFreeCmeCustomer } from '@/global/js/magento-api-library/customer/updateCustomer'
import { getAndSetCustomer } from '../../composables/user/checkIfUserLoggedIn'
import Customer from '@/global/js/magento-api-library/types/Customer'
import fetchCustomerDropDowns from '../../composables/user/fetchCustomerDropDowns'
import log from '../../../../global/js/utils/logging'

interface DropdownItem {
  attribute_options: { value: string; label: string }[];
}

interface FieldData {
  title: string,
  selectedOption: {
    label: string,
    value: string,
  } | null,
  errorMessage: string | null,
}

// Define required field and missing field objects
const missingFields = ref<{ key: keyof Customer; label: string }[] | null>(null)
const requiredFields: { key: keyof Customer; label: string }[] = [
  { key: 'freecme_credentials', label: 'credentials' },
  { key: 'freecme_profession', label: 'profession' },
  { key: 'freecme_specialty', label: 'specialty' },
]

// Define Data Objects (used to handle updated input from dropdowns)
const credentialData = ref<FieldData>({
  title: 'Credentials',
  selectedOption: null,
  errorMessage: null,
})

const specialtyData = ref<FieldData>({
  title: 'Specialty',
  selectedOption: null,
  errorMessage: null,
})

const professionData = ref<FieldData>({
  title: 'Profession',
  selectedOption: null,
  errorMessage: null,
})

// Define Missing Credential Modal Data
const missingCredsModalTitle = 'Missing Profile Information'
const missingCredsModalDescription = 'This information is needed to properly track your participation in the course.'

// Define dropdown data collections
const credentialResources = ref([{ value: '', label: 'Select an Option' }])
const specialtyResources = ref([{ value: '', label: 'Select an Option' }])
const professionResources = ref([{ value: '', label: 'Select An Option' }])

// Set Missing Data Flags For Credential Fields
const isCredentialMissing = ref(false)
const isSpecialtyMissing = ref(false)
const isProfessionMissing = ref(false)

// Map field type to the field's data reference
const fieldDataMap = {
  credentials: credentialData,
  profession: professionData,
  specialty: specialtyData,
}

// Define Customer Data
const customer = ref<Customer | null>(null)

// Define local states
const isLoading = ref(false)
const startActivityIsReady = ref(false)
const threwError = ref(false)
const threwErrorMsg = ref('')

/**
 * Handles detecting missing credential data
 */
const userHasMissingCredentials = async (): Promise<boolean> => {
  let missingFieldsFound = false

  try {
    // Attempt to load customer data from localStorage
    const customerData = localStorage.getItem('customer')
    customer.value = customerData ? (JSON.parse(customerData) as Customer) : null

    // Confirm customer data exists
    if (customer.value) {

      // Check for missing fields
      missingFields.value = requiredFields.filter(
        field => !customer.value?.[field.key] || customer.value?.[field.key] === '' || customer.value?.[field.key] === ' ',
      )

      // If any fields are missing
      if (missingFields.value.length > 0) {
        
        // Map field type to the field's isMissing flag
        const fieldMap = {
          credentials: isCredentialMissing,
          profession: isProfessionMissing,
          specialty: isSpecialtyMissing,
        }

        // Iterate through missing fields and set corresponding isMissing flags to true
        missingFields.value.forEach((field) => {
          if (field.label in fieldMap) {
            fieldMap[field.label as keyof typeof fieldMap].value = true
          }
        })

        missingFieldsFound = true

        // Only load the dropdown data if we need to show it
        await fetchFieldData()
      }
    }
  } catch (e) {
    log(e as string, 'src/freecme/js/composables/user/checkMissingCredentials.ts')
  }

  return missingFieldsFound
}

/**
 * Handles getting credential, specialty, and profession dropdown values
 */
const fetchFieldData = async (): Promise<void> => {
  try {
    // Get dropdown data from Magento
    const dropDowns = await fetchCustomerDropDowns()

    // Drop the first record in each array (it will always be empty)
    const processedDropDowns = dropDowns?.map((item: DropdownItem) => ({
      ...item,
      attribute_options: item.attribute_options.slice(1),
    }))

    // Set the field data
    if(processedDropDowns) {
      professionResources.value = processedDropDowns[0]?.attribute_options || []
      specialtyResources.value = processedDropDowns[1]?.attribute_options || []
      credentialResources.value = processedDropDowns[2]?.attribute_options || []
    }
  } catch (e) {
    log(e as string, 'src/freecme/js/composables/user/checkMissingCredentials.ts')
  }
}

/**
 * Handles Form Submission Action
 */
const submitForm = async (): Promise<void> => {
  let missingFieldsExist = false
  let missingRequiredData = false

  // Iterate through missing fields
  missingFields.value?.forEach((field) => {
    // For each missing field, get it's corresponding data
    if (field.label in fieldDataMap) {
      const fieldData = fieldDataMap[field.label as keyof typeof fieldDataMap].value

      // If the field is marked as missing but has no data
      if (fieldData.selectedOption === null) {

        // Flag missing data
        missingRequiredData = true

        // Set an error for the specific field
        fieldData.errorMessage = fieldData.title + ' is a required field'
      } else {
        // The field has a value set, make sure no error message exists
        fieldData.errorMessage = null
      }

      // Update flag to signal at least one missing field exists
      missingFieldsExist = true
    }
  })

  // Submit form after all missing fields are evaluated and no errors are found
  if(missingFieldsExist && !missingRequiredData) {

    // Try to update the customer's data and refresh saved customer data in local storage
    try {
      isLoading.value = true
      
      await updateFreeCmeCustomer(
        customer.value?.freecme_credentials?.trim() 
          ? customer.value?.freecme_credentials 
          : fieldDataMap['credentials'].value.selectedOption?.value ?? '',
        customer.value?.freecme_specialty?.trim() 
          ? customer.value?.freecme_specialty 
          : fieldDataMap['specialty'].value.selectedOption?.value ?? '',
        customer.value?.freecme_profession?.trim() 
          ? customer.value?.freecme_profession 
          : fieldDataMap['profession'].value.selectedOption?.value ?? '',
        customer.value?.freecme_credentials_2 ?? '',
        customer.value?.freecme_specialty_2 ?? '',
        customer.value?.freecme_profession_2 ?? '',
        customer.value?.freecme_credentials_3 ?? '',
        customer.value?.freecme_specialty_3 ?? '',
        customer.value?.freecme_profession_3 ?? '',
        undefined,
        customer.value?.addresses[0].country_code,
        customer.value?.addresses[0].region.region_code,
        customer.value?.addresses[0].postcode,
      )

      //Refresh customer data
      localStorage.removeItem('customer')
      await getAndSetCustomer()
      customer.value = await getCustomer()

      // Triggers start activity in ProductDetailsPage component
      startActivityIsReady.value = true
    } catch(e) {
      isLoading.value = false
      threwError.value = true
      threwErrorMsg.value = e as string
      log(e as string, 'src/freecme/js/composables/user/checkMissingCredentials.ts')
    }
  }
}

/**
 * Handles drop down value change event
 */
const handleChange = ({ item, fieldName }: { item: { label: string; value: string }; fieldName: string }): void => {
  const fieldData = fieldDataMap[fieldName as keyof typeof fieldDataMap]
  fieldData.value.selectedOption = item
}

// Define Export Objects
export const credentialsData = {
  credentialData,
  professionData,
  specialtyData,
}

export const credentialsCheck = {
  missingFields,
  fieldDataMap,
  userHasMissingCredentials,
  handleChange,
  submitForm,
}

export const credentialsMissingFlags = {
  isCredentialMissing,
  isSpecialtyMissing,
  isProfessionMissing,
}

export const credentialsModal = {
  missingCredsModalTitle,
  missingCredsModalDescription,
}

export const credentialsResources = {
  credentialResources,
  professionResources,
  specialtyResources,
}

export const credentialsLocalState = {
  isLoading,
  startActivityIsReady,
  threwError,
  threwErrorMsg,
}